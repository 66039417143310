import Alert from "@material-ui/lab/Alert";
import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import RegularInput from "../components/RegularInput";
import { AuthContext } from "../contexts/AuthContext";
import api from "../services/api";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Scroll from "react-scroll";
import MenuIcon from '@material-ui/icons/Menu';

const ScrollLink = Scroll.Link;

const { Grid, Button, Container, useMediaQuery, IconButton,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Collapse } = require("@material-ui/core");

function HeaderLP(props) {
    const history = useHistory();
    const location = useLocation();
    const desktop = useMediaQuery('(min-width:769px)');
    const { auth, authMethods } = useContext(AuthContext);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogForgotPassword, setOpenDialogForgotPassword] = React.useState(false);
    const [open, setOpen] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [shouldScrollToTop, setShouldScrollToTop] = useState(true);
    const [formData, setFormData] = useState({
        email: null,
        password: null
    });

    const toggleMenu = (e) => {
        e.preventDefault();
        setOpenMenu(!openMenu);
    }

    const setField = (field) => (event) => {
        event.persist();
        setFormData((prevState) => ({
            ...prevState,
            [field]: event.target.value,
        }));
    };

    const setFieldValue = (field) => (value) => {
        setFormData((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const setFieldChecked = (field) => (event) => {
        setFormData((prevState) => ({
            ...prevState,
            [field]: event.target.checked,
        }));
    };
    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleClickOpenDialogForgotPassword = () => {
        setOpenDialogForgotPassword(true);
    };
    const handleCloseDialogForgotPassword = () => {
        setOpenDialogForgotPassword(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setOpen(false);
        api.post("/Token",
            "grant_type=password&username=" + formData.email + "&password=" + formData.password
            , {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then((tokenObject) => {
                api.get("/api/Account/UserInfo", {
                    headers: {
                        Authorization: "Bearer " + tokenObject.data.access_token,
                        Accept: "application/json",
                    },
                }).then((userInfo) => {
                    var data = userInfo.data;
                    authMethods.login(data.user, tokenObject.data.access_token, tokenObject.data.access_token, data.isSubscribed);
                    handleCloseDialog();
                    setFormData({
                        email: null,
                        password: null
                    })
                    goToAccount();
                }).catch((error) => {
                    setOpen(true);
                });
            }).catch((error) => {
                setOpen(true);
            });
    };

    const handleSubmitForgotPassword = (e) => {
        e.preventDefault();
        api.get(`/api/Account/ResetPassword?email=${formData.email}`).then((userInfo) => {
            handleCloseDialogForgotPassword();
        }).catch((error) => {
            console.log(error);
            handleCloseDialogForgotPassword();
        });
    }

    const goToforgotPassword = (e) => {
        handleCloseDialog();
        handleClickOpenDialogForgotPassword();
    }

    const goToAccount = () => {
        history.push("/accueil/mon-compte");
    }

    const goToFunnel = () => {
        history.push("/accueil");
    }

    const goToRegister = () => {
        history.push("/inscription");
    }

    const goToLP = () => {
        history.push("/services");
    }

    useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        if (shouldScrollToTop) {
            window.scrollTo(0, 0);
        }
        setShouldScrollToTop(false);
    })

    return (
        <div id="navbar" className="lp-navbar">
            <div className={openMenu ? "lp-bg-white" : ""}>
                <Container style={{ paddingTop: desktop ? "26px" : "7px" }} >
                    <Grid container direction="row" alignItems="center" justify="space-between"  >
                        <Grid item onClick={goToLP}> <img src="/img/logo-bw.svg" className="logo-lp" ></img> </Grid>
                        <Grid item className="display-mobile">
                            <Grid container direction="row" alignItems="center" justify="flex-end">
                                <Grid item>
                                    {auth.accessToken != null ? <></> :
                                        <Button variant="outlined" color="primary" className="lp-cta-header" onClick={goToRegister} >Offre d'essai</Button>
                                    }
                                </Grid>
                                <Grid item>
                                    <IconButton color="secondary" onClick={toggleMenu} >
                                        <MenuIcon className="big-icon" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className="display-desktop">
                            <Grid container alignItems="center" spacing={3}>
                                <Grid item>
                                    <ScrollLink
                                        className="navy"
                                        smooth={true}
                                        duration={500}
                                        to="lp-services"
                                        offset={-127}
                                    >
                                        <Button className="lp-header-button" > Services</Button>
                                    </ScrollLink>
                                </Grid>
                                <Grid item>
                                    <ScrollLink
                                        className="navy"
                                        smooth={true}
                                        duration={500}
                                        to="lp-cta"
                                        offset={-92}
                                    >
                                        <Button className="lp-header-button">Tarifs</Button>
                                    </ScrollLink>
                                </Grid>
                                <Grid item> <Button className="lp-header-button" onClick={goToFunnel} >Générer un justificatif</Button> </Grid>
                                <Grid item>

                                    <ScrollLink
                                        className="navy"
                                        smooth={true}
                                        duration={500}
                                        to="lp-register"
                                        offset={-127}
                                    >
                                        <Button className="lp-header-button">Inscription</Button>
                                    </ScrollLink>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className="display-desktop">
                            <Grid container alignItems="center" justify="flex-end" spacing={3}>
                                <Grid item>
                                    {auth.accessToken != null ? <Button variant="outlined" color="primary" className="lp-cta-header" onClick={goToAccount} style={{ minWidth: "122px" }}>Mon Compte</Button> :
                                        <Button variant="outlined" endIcon={<ArrowForwardIcon />} color="primary" className="lp-cta-header" onClick={handleClickOpenDialog} style={{ minWidth: "122px" }}>Se connecter</Button>
                                    }
                                </Grid>
                                <Grid item>
                                    {auth.accessToken != null ? <></> :
                                        <Button variant="contained" endIcon={<ArrowForwardIcon />} color="primary" className="lp-cta-header" onClick={goToRegister} style={{ minWidth: "122px" }}>Profitez de l'offre d'essai</Button>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Collapse in={openMenu} className="lp-menu-mobile" >
                            <div >
                                <Grid container direction="column" alignItems="center" justify="center" >
                                    <Grid item>
                                        <ScrollLink
                                            className="navy"
                                            smooth={true}
                                            duration={500}
                                            to="lp-services"
                                            offset={-101}
                                        >
                                            <Button className="lp-header-button" onClick={toggleMenu} > Services</Button>
                                        </ScrollLink>
                                    </Grid>
                                    <Grid item>
                                        <ScrollLink
                                            className="navy"
                                            smooth={true}
                                            duration={500}
                                            to="lp-cta"
                                            offset={-66}
                                        >
                                            <Button className="lp-header-button" onClick={toggleMenu}>Tarifs</Button>
                                        </ScrollLink>
                                    </Grid>
                                    <Grid item> <Button className="lp-header-button" onClick={goToFunnel} >Générer un justificatif</Button> </Grid>
                                    <Grid item>

                                        <ScrollLink
                                            className="navy"
                                            smooth={true}
                                            duration={500}
                                            to="lp-register"
                                            offset={-101}
                                        >
                                            <Button className="lp-header-button" onClick={toggleMenu}>Inscription</Button>
                                        </ScrollLink>
                                    </Grid>
                                    <Grid item>
                                        {auth.accessToken != null ? <Button variant="outlined" color="primary" className="lp-cta-header lp-cta-header-mobile-menu" onClick={goToAccount} style={{ minWidth: "122px" }}>Mon Compte</Button> :
                                            <Button variant="outlined" endIcon={<ArrowForwardIcon />} color="primary" className="lp-cta-header lp-cta-header-mobile-menu" onClick={handleClickOpenDialog} style={{ minWidth: "122px" }}>Se connecter</Button>
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </Collapse>
                    </Grid>


                    <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title" maxWidth="md">
                        <DialogTitle id="form-dialog-title">Se Connecter</DialogTitle>
                        <form onSubmit={handleSubmit}>
                            <DialogContent>
                                <Collapse in={open}>
                                    <Alert
                                        style={{ marginBottom: "15px" }}
                                        severity="error"
                                        action={
                                            <i
                                                className="fas fa-times"
                                                style={{ color: "inherit", cursor: "pointer" }}
                                                onClick={() => setOpen(false)}
                                            ></i>
                                        }
                                    >
                                        L'email, le mot de passe ou les deux sont incorrects.
                                    </Alert>
                                </Collapse>
                                <div >
                                    <RegularInput
                                        autoFocus
                                        placeholder="E-mail"
                                        value={formData.email}
                                        onChange={setField("email")}
                                        required
                                        type="email"
                                    ></RegularInput>
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <RegularInput
                                        placeholder="Mot de passe"
                                        value={formData.password}
                                        onChange={setField("password")}
                                        required
                                        type="password"
                                    ></RegularInput>
                                </div>
                                <div >
                                    <div className="forgot-password" onClick={goToforgotPassword}>
                                        Mot de passe oublié ?
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary" className="header-button">
                                    Annuler
                                </Button>
                                <Button type="submit" color="primary" className="header-button">
                                    Connection
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                    <Dialog open={openDialogForgotPassword} onClose={handleCloseDialogForgotPassword} aria-labelledby="form-dialog-title" maxWidth="md">
                        <DialogTitle id="form-dialog-title">Mot de passe oublié</DialogTitle>
                        <form onSubmit={handleSubmitForgotPassword}>
                            <DialogContent>
                                <div className="forgot-password-description">
                                    Entrez votre email pour recevoir un email de réinitialisation de votre mot de passe
                                </div>
                                <div >
                                    <RegularInput
                                        autoFocus
                                        placeholder="E-mail"
                                        value={formData.email}
                                        onChange={setField("email")}
                                        required
                                        type="email"
                                    ></RegularInput>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialogForgotPassword} color="primary" className="header-button">
                                    Annuler
                                </Button>
                                <Button type="submit" color="primary" className="header-button">
                                    Réinitialiser mon mot de passe
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                </Container>
            </div>
        </div>
    )
}

export default HeaderLP