import React, { createContext, useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getGoogleRecaptchaValidation } from "./GoogleRecaptchaService";

export const GoogleRecaptchaContext = createContext();
export const useGoogleRecaptcha = () => useContext(GoogleRecaptchaContext);

const GoogleRecaptchaContextProvider = (props) => {
    var location = useLocation()
    // Attention seulement 4 niveaux de score dans notre version 0.1, 0.3, 0.7 et 0.9
    const scoreLimit = 0.6;

    const siteKey = "6Lfhjp4kAAAAAGx2_BnHW6hc5Aj28mM5Ojorr5-A";
    const [validationScore, setValidationScore] = useState(() => {
        const localData = typeof localStorage !== 'undefined' && localStorage.getItem("validation");
        return localData
            ? JSON.parse(localData)
            : null;
    });

    useEffect(() => {
        localStorage.setItem("validation", JSON.stringify(validationScore));
    }, [validationScore]);

    useEffect(() => {
        const handleRouteChange = (url) => {
            const localData = typeof localStorage !== 'undefined' && localStorage.getItem("validation");
            var score = JSON.parse(localData)
            handleRequestResult(url, score)
        }
        handleRouteChange(location.pathname);
    }, [location]);

    const history = useHistory();

    const loadRecaptchaEnterprise = (callback) => {
        const existingScript = document.getElementById('recaptcha-enterprise');
        console.log("loaded")
        if (!existingScript) {
            const script = document.createElement('script');
            console.log(siteKey)
            script.src = `https://www.google.com/recaptcha/enterprise.js?render=${siteKey}`;
            script.id = 'recaptcha-enterprise';
            document.body.appendChild(script);
           
            script.onload = () => {
                if (callback) callback();
            };
        }
        if (existingScript && callback) callback();
    };

    const handleRequestResult = (url, result) => {
        if (result != null && !url.startsWith("/forbidden") && (result?.tokenProperties?.valid == false || result?.riskAnalysis.score < scoreLimit)) {
            history.push("/forbidden");
        }
    }

    const validateToken = async () => {
        const token = await window.grecaptcha.enterprise.execute(siteKey, { action: 'landing' });
        getGoogleRecaptchaValidation({ token: token, action: "landing" }).then(res => {
            setValidationScore(res?.data);
            var url = location.pathname;
            handleRequestResult(url, res?.data)
        })
    }

    useEffect(() => {
        loadRecaptchaEnterprise(() => {
            window.grecaptcha.enterprise.ready(validateToken);
        })
    }, []);

    return (
        <GoogleRecaptchaContext.Provider value={validationScore}>
            {props.children}
        </GoogleRecaptchaContext.Provider>
    );
};

export default GoogleRecaptchaContextProvider;