import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    FormControl, RadioGroup, CircularProgress,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';
import MainInput from "./MainInput";
import MainCheckbox from "./MainCheckbox";
import { DataContext } from '../contexts/DataContext';
import RegularInput from "./RegularInput";
import { AuthContext } from "../contexts/AuthContext.js";
import api from "../services/api.js";
import Alert from "@material-ui/lab/Alert";
import { getKbis } from "../services/FileService";
import { createKbis } from "../services/FileService";
import { getSocietes, hasKbis } from "../services/SocieteService";


const AccountNew = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [openError, setOpenError] = useState(false);
    const history = useHistory();
    const { auth, authMethods } = useContext(AuthContext);
    const [kbiss, setKbiss] = useState([]);
    const [kbisLastMonth, setKbisLastMonth] = useState([]);

    const [openDialog, setOpenDialog] = useState(false);
    const [societeState, setSocieteState] = useState({
        loading: true,
        data: []
    });

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const [formData, setFormData] = useState({
        searchInput: undefined
    });

    useEffect(() => {
        async function getAndSetKbiss() {
            var kbisResult = await getKbis(auth);
            if(kbisResult.data){
                var kbissOrderedByDate = kbisResult.data.sort(function (a, b) {
                    return new Date(b.date) - new Date(a.date);
                });
                if (kbisResult) setKbiss(kbissOrderedByDate);
            }
        }
        getAndSetKbiss();
    }, []);

    useEffect(() => {
        var lastMonthDate = new Date(Date.now());
        lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
        var lastMonthKbis = [];
        kbiss.forEach((k) => {
            if (lastMonthDate < new Date(k.createdAt)) {
                lastMonthKbis.push(k);
            }
        });
        setKbisLastMonth(lastMonthKbis);
    }, [kbiss]);

    const setField = (field) => (event) => {
        event.persist();
        setFormData((prevState) => ({
            ...prevState,
            [field]: event.target.value,
        }));
    };

    const selectSociete = async (societe) => {
        var s = await hasKbis(societe.siren);
        var hasKbisValue = (s != null && s.data != null) ? s.data : true
        let kbis = {
            name: societe.name,
            SIREN: societe.siren,
            isInsee: !hasKbisValue
        }
        let resut = await createKbis(kbis, auth);
        history.push("/accueil/mon-compte");
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        setSocieteState({
            loading: true,
            data: []
        });
        handleOpenDialog();
        var s = await getSocietes(formData.searchInput);
        if (s) setSocieteState({
            loading: false,
            data: s.data
        });

    };

    const desktop = useMediaQuery('(min-width:769px)');

    return (
        <div className="account-container">
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title" className="societe-dialog">
                <DialogTitle>
                    <Grid container direction="row" alignItems="flex-start" justify="space-between">
                        <Grid item>
                            <div className="dialog-title display-desktop">Sélectionner votre société</div>
                        </Grid>
                        <Grid item >
                            <Button type="button" onClick={handleCloseDialog}  ><img src="/img/close.svg"></img> </Button>
                        </Grid>
                    </Grid>
                    <div className="dialog-title display-mobile">Sélectionner votre société</div>
                </DialogTitle>
                <DialogContent>
                    {societeState.data.map(societe => {
                        return (<div className="societe-container" onClick={() => selectSociete(societe)}>
                            <Grid container  >
                                <Grid item xs={12} md={8}>
                                    <div className="info-dialog"><span className="info-title-dialog">NOM : </span> <span className="info-value-dialog">{societe.name}</span></div>
                                    <div className="info-dialog"><span className="info-title-dialog">SIREN : </span> <span className="info-value-dialog">{societe.siren}</span></div>
                                    <div className="info-dialog"><span className="info-title-dialog">ADRESSE : </span> <span className="info-value-dialog">{societe.adress}</span></div>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Grid container alignItems="center" justify="center" style={{ height: "100%" }}>
                                        <Button variant="contained" color="primary" className="dialog-button">
                                            <Grid container alignItems="center" >
                                                <Grid item>Valider</Grid>
                                                <img src="/img/arrow-right.svg" ></img>
                                            </Grid>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>)
                    })}
                    {!societeState.loading && societeState.data.length == 0 ? <div className="zero-result">Pas de résultat trouvé</div> : <></>}
                </DialogContent>
            </Dialog>
            <div className="account-title">Nouveau KBIS</div>
            <div className="account-body">
                <Typography variant="h2">
                    Vous avez téléchargé {kbisLastMonth.length} kbis ce mois ci.
                </Typography>
                <div className="document-count">
                    {5 - kbisLastMonth.length}/5 restant
                </div>
                {
                    5 - kbisLastMonth.length > 0 && auth.isSubscribed ?
                    <form className="main-form" onSubmit={handleSubmit}>
                        <MainInput
                            autoFocus
                            placeholder="SIREN ou SIRET ou Raison sociale"
                            value={formData.searchInput}
                            onChange={setField("searchInput")}
                            required
                        ></MainInput>
                        <Grid container direction="row" alignItems="center" justify={desktop ? "flex-end" : "center"} >
                            <Grid item>
                                <Button type="submit" variant="contained" color="primary" className="cta">
                                    <Grid container alignItems="center" >
                                        <Grid item>Obtenir mon KBIS</Grid>
                                        <img src="/img/arrow-right.svg" ></img>
                                    </Grid>
                                </Button>
                            </Grid>
                        </Grid>
                    </form> : <></>
                }

            </div>
        </div>
    );
};

export default AccountNew;
