import React, { useState, useContext, useEffect } from "react";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    FormControl, RadioGroup, CircularProgress, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle
} from '@material-ui/core';


const Clients = () => {
    const desktop = useMediaQuery('(min-width:769px)');

    return (
        <section className="lp-section lp-section-service">
            <Grid container direction="column" alignItems="center" justify="center">
                <Grid item>
                    <div className="lp-section-title">
                        Une solution simple et unique  <br />
                        pour différents profils de professionnels !
                    </div>
                    <div className="lp-section-title-description2">
                        Vous gérez beaucoup de dossiers ou des besoins réguliers de justificatifs sociétés , Portail des Sociétés est fait pour vous.
                    </div>
                </Grid>
                <Grid item>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <div className="lp-client-container">
                                <Grid container direction="column" alignItems="center" justify="center">
                                    <Grid item>
                                        <div className="lp-client-title">
                                            Sociétés marchandes
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div className="lp-client-description ">
                                        Pour des besoins importants en création de dossiers dans le <br className="display-desktop"/> cadre de collaboration, import/export …
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <img className="lp-service-image" src="/img/lp/client1.svg"></img>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="lp-client-container">
                                <Grid container direction="column" alignItems="center" justify="center">
                                    <Grid item>
                                        <div className="lp-client-title">
                                            Constructeurs immobiliers
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div className="lp-client-description">
                                        Pour un besoin important en prestataires, fournisseurs et vérifications<br className="display-desktop"/> du statut de leurs collaborateurs indépendants
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <img className="lp-service-image" src="/img/lp/client2.svg"></img>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="lp-client-container">
                                <Grid container direction="column" alignItems="center" justify="center">
                                    <Grid item>
                                        <div className="lp-client-title">
                                            Indépendant / Auto-entrepreneur
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div className="lp-client-description">
                                            Livreurs, vtc…qui doivent souvent justifier de leurs statuts et<br className="display-desktop"/> collaborer avec différents employeurs
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <img className="lp-service-image " src="/img/lp/client3.svg"></img>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="lp-client-container">
                                <Grid container direction="column" alignItems="center" justify="center">
                                    <Grid item>
                                        <div className="lp-client-title">
                                            Cabinets
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div className="lp-client-description">
                                            Comptables/avocats… pour des besoins de génération d’un certain<br className="display-desktop"/> volume de documents et monter des dossiers pour leurs différents clients
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <img className="lp-service-image " src="/img/lp/client4.svg"></img>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </section>
    )
}
export default Clients;