import React, { useState, useContext, useEffect } from "react";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    FormControl, RadioGroup, CircularProgress, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, IconButton, Container
} from '@material-ui/core';
import Carousel, { consts } from 'react-elastic-carousel'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


const Companies = () => {

    const myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ? <ArrowBackIcon className="big-icon" /> : <ArrowForwardIcon className="big-icon" />
        return (
            <div className="lp-testimonial-arrow-button-container">
                <IconButton className="lp-testimonial-icon-button" color="primary" aria-label="upload picture" component="span" onClick={onClick} disabled={isEdge}>
                    {pointer}
                </IconButton>
            </div>
        )
    }

    const desktop = useMediaQuery('(min-width:769px)');

    return (
        <section className="lp-section">
            <Grid container direction="column" alignItems="center" justify="center">
                <Grid item>
                    <div className="lp-section-title">
                        Ils parlent de nous
                    </div>
                    <div className="lp-section-title-description lp-section-title-description-companies">
                        Nous sommes fiers de fournir un service exceptionnel à nos clients, mais vous n’êtes pas obligé(e) de nous croire sur parole. Lisez ce que nos clients ont à dire sur notre collaboration.
                    </div>
                </Grid>
                
                <Grid item>
                    <Grid container spacing={3} >
                        <Grid item xs={4} md={2}>
                            <Grid container alignItems="center" justify="center" className="lp-full-height">
                                <Grid item>
                                    <a target="_blank" href="https://www.bfmtv.com/economie/professionnels/portail-des-societes-la-plateforme-de-centralisation-et-telechargement-de-documents-professionnels_AB-202204290575.html"><img src="/img/lp/partner1.png" className="lp-partner-image"></img></a></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Grid container alignItems="center" justify="center" className="lp-full-height">
                                <Grid item>
                                <a target="_blank" href="https://www.latribune.fr/supplement/gestion-de-documents-professionnels-quelle-plateforme-choisir-903427.html"><img src="/img/lp/partner2.jpg" className="lp-partner-image"></img></a></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Grid container alignItems="center" justify="center" className="lp-full-height">
                                <Grid item>
                                <a target="_blank" href="https://www.entreprise-et-droit.com/avis-portail-des-societes-la-plateforme-de-telechargement-de-documents-legaux-pour-les-entreprises/"><img src="/img/lp/partner3.jpg" className="lp-partner-image"></img></a></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Grid container alignItems="center" justify="center" className="lp-full-height">
                                <Grid item>
                                <a target="_blank" href="https://www.actinbusiness.com/portail-des-societes-indispensable-aux-professionnels-de-nos-jours/"><img src="/img/lp/partner4.jpg" className="lp-partner-image"></img></a></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Grid container alignItems="center" justify="center" className="lp-full-height">
                                <Grid item>
                                <a target="_blank" href="https://www.leguidedesce.fr/simplifier-la-gestion-de-vos-justificatifs-professionnels/"><img src="/img/lp/partner5.jpg" className="lp-partner-image"></img></a></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Grid container alignItems="center" justify="center" className="lp-full-height">
                                <Grid item>
                                <a target="_blank" href="https://www.dtp-ag.com/portail-des-societes-une-bonne-alternative-a-infogreffe-notre-avis/"><img src="/img/lp/partner6.jpg" className="lp-partner-image"></img></a></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item className="lp-testimonials-container">
                    <Carousel itemsToShow={1} renderArrow={myArrow}
                        renderPagination={({ pages, activePage, onClick }) => {
                            return (
                                <Grid container direction="row" alignItems="center" justify="center" spacing={2} className="lp-dot-container">
                                    {pages.map(page => {
                                        const isActivePage = activePage === page
                                        return (
                                            <Grid item>
                                                <div
                                                    className={isActivePage ? "lp-dot-comment-active" : "lp-dot-comment"}
                                                    key={page}
                                                    onClick={() => onClick(page)}
                                                />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            )
                        }}
                    >
                        <div className="lp-testimonial-description">
                            <div className="lp-testimonial-desciprion-container">
                                <div className="lp-testimonial-quote-left">“</div>
                                <div className="lp-testimonial-quote-right">“</div>
                                <div className="lp-testimonial-comment">Bon concept, c'est cool de plus avoir besoin de chercher ses documents partout à chaque fois et avoir ses documents tout le temps à jour sur son téléphone ou ordi.</div>
                                <div className="lp-testimonial-name">Céline G.</div>
                            </div>
                        </div>
                        <div className="lp-testimonial-description">
                            <div className="lp-testimonial-desciprion-container">
                                <div className="lp-testimonial-quote-left">“</div>
                                <div className="lp-testimonial-quote-right">“</div>
                                <div className="lp-testimonial-comment">Super service, fluide et rapide !</div>
                                <div className="lp-testimonial-name">Christian F.</div>
                            </div>
                        </div>
                        <div className="lp-testimonial-description">
                            <div className="lp-testimonial-desciprion-container">
                                <div className="lp-testimonial-quote-left">“</div>
                                <div className="lp-testimonial-quote-right">“</div>
                                <div className="lp-testimonial-comment">J'ai eu un bug sur le site, mais le service client répond tout de suite, et accompagne parfaitement.</div>
                                <div className="lp-testimonial-name"> Ahmed A.</div>
                            </div>
                        </div>
                        <div className="lp-testimonial-description">
                            <div className="lp-testimonial-desciprion-container">
                                <div className="lp-testimonial-quote-left">“</div>
                                <div className="lp-testimonial-quote-right">“</div>
                                <div className="lp-testimonial-comment">Une équipe au top, réactive et à l'écoute.</div>
                                <div className="lp-testimonial-name">Bertrand M.</div>
                            </div>
                        </div>
                        <div className="lp-testimonial-description">
                            <div className="lp-testimonial-desciprion-container">
                                <div className="lp-testimonial-quote-left">“</div>
                                <div className="lp-testimonial-quote-right">“</div>
                                <div className="lp-testimonial-comment">Rapide et simple d'utilisation.</div>
                                <div className="lp-testimonial-name">Marie H.</div>
                            </div>
                        </div>
                        <div className="lp-testimonial-description">
                            <div className="lp-testimonial-desciprion-container">
                                <div className="lp-testimonial-quote-left">“</div>
                                <div className="lp-testimonial-quote-right">“</div>
                                <div className="lp-testimonial-comment">Merci à Eric pour son accompagnement et l'envoi de mes documents à mes prestataires sur un simple appel !</div>
                                <div className="lp-testimonial-name">Laurine R.</div>
                            </div>
                        </div>
                        <div className="lp-testimonial-description">
                            <div className="lp-testimonial-desciprion-container">
                                <div className="lp-testimonial-quote-left">“</div>
                                <div className="lp-testimonial-quote-right">“</div>
                                <div className="lp-testimonial-comment">Quelques petits soucis avec la plateforme de temps à autre, mais l'équipe est toujours joignable pour prendre le relais.</div>
                                <div className="lp-testimonial-name">Jérémie F.</div>
                            </div>
                        </div>
                    </Carousel>

                </Grid>

            </Grid>
        </section>
    )
}
export default Companies;