import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import MainInput from "../components/MainInput";
import MainCheckbox from "../components/MainCheckbox";
import { getSocietes } from "../services/SocieteService";
import { DataContext } from '../contexts/DataContext';
import { AuthContext } from "../contexts/AuthContext.js";
import { createKbis } from "../services/FileService";

const LegalNotice = () => {

    const history = useHistory();
    const { societe, setSociete, user, setUser } = useContext(DataContext);
    const desktop = useMediaQuery('(min-width:769px)');
    const { auth } = useContext(AuthContext);

    return (<div>
        <div className="title-generic">
            Mentions Légales
        </div>
        <div className="white-container white-container-content">
            <div className="subtitle-generic">L'éditeur du site est :</div>
            <div className="body-generic">
                OUI LIT LTD <br></br>
                Email : contact@portail-des-societes.com <br/>
                Tél :  01 88 40 56 65
            </div>

            <div className="subtitle-generic">L'entreprise :</div>
            <div className="body-generic">
                Dénomination sociale : OUI LIT LTD<br></br>
                Forme juridique : LTD<br></br>
                Déclarée sous License n° 13584952  <br></br>
                Office 4 219 Kensington High Street Kensington London W8 6BD
            </div>

            <div className="subtitle-generic">Le Directeur de la publication est :</div>
            <div className="body-generic">
                OUI LIT LTD<br></br>
                Email : contact@portail-des-societes.com<br></br>
                L'hébergement du site internet portail-des-societes.com est assuré par :<br></br>
                OVH<br></br>
                Adresse : 2 rue Kellermann – 59100 Roubaix<br></br>
                Tél : 0899 701 761 (3€ TTC l’appel + prix de l'appel)
            </div>


        </div>
    </div>
    )
}
export default LegalNotice;