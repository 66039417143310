import React, { useState, useContext, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { AuthContext } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
const { Grid, Button, Container, useMediaQuery, Typography } = require("@material-ui/core");

function Footer(props) {
    const history = useHistory();
    const location = useLocation();
    const desktop = useMediaQuery('(min-width:769px)');
    const [footerInformation, setFooterInformation] = useState();
    const [sizeFooterInformation, setSizeFooterInformation] = useState("12px");
    const { auth } = useContext(AuthContext);

    useEffect(
        () => {
            switch (location.pathname) {
                case '/accueil/email':
                    setSizeFooterInformation("13px");
                    setFooterInformation("Portail des sociétés est une solution simple, indépendante et privée d’aide aux entreprises dans l’obtention et la gestion de documents professionnels.");
                    break;
                case '/accueil/paiement':
                    setSizeFooterInformation("13px");
                    //setFooterInformation(<span>*Période d’essai et découverte de l’outil à 1€ valable 48h, ensuite le prix de l’abonnement est de 49,90€ par mois : <span onClick={goToCGU} className="link">Conditions générales</span></span>);
                    setFooterInformation("Portail des sociétés est une solution simple, indépendante et privée d’aide aux entreprises dans l’obtention et la gestion de documents professionnels.");
                    break;
                default:
                    setSizeFooterInformation("13px");
                    setFooterInformation("Portail des sociétés est une solution simple, indépendante et privée d’aide aux entreprises dans l’obtention et la gestion de documents professionnels.");
            }
        },
        [location]
    )

    const goToCGU = () => {
        history.push("/accueil/conditions-generales-utilisation");
    }

    const goToPrivacyPolicy = () => {
        history.push("/accueil/politique-de-confidentialite");
    }

    const goToLegalNotice = () => {
        history.push("/accueil/mentions-legales");
    }
    const goToContact = () => {
        history.push("/accueil/contact");
    }
    const goToSubscribe = () => {
        history.push("/inscription");
    }
    const goToUnsubscribe = () => {
        history.push("/accueil/desinscription");
    }

    const goToAccount = () => {
        history.push("/accueil/mon-compte/details");
    }
    const goToServices = () => {
        history.push("/accueil/services");
    }

    return (
        <div className="footer">
            <div className="info-footer">
                <Container>
                    <Grid container alignItems="center" justify="center">
                        <Typography variant="body2" className="text-center" style={{ fontSize: sizeFooterInformation, color: "#717171" }} >{footerInformation}</Typography>
                    </Grid>
                </Container>
            </div>
            <Container className="footer-content">
                <Grid container>
                    <Grid item md={3} className="display-desktop ">
                        <img src="/img/logo-color.svg"></img>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Grid container direction="row" className="footer-link-container">
                            <Grid item xs={7} md={6}>
                                <div className="footer-link" onClick={goToCGU}>Conditions générales d'utilisation</div>
                                <div className="footer-link" onClick={goToPrivacyPolicy} >Politique de confidentialité</div>
                                <div className="footer-link" onClick={goToLegalNotice}>Mentions légales</div>
                                <div className="fake-footer-link"></div>
                            </Grid>
                            <Grid item xs={5} md={6}>
                                {auth.isSubscribed ? <div className="footer-link" onClick={goToAccount}>Mon compte</div> : <></>}
                                <div className="footer-link" onClick={goToServices}>Services et tarifs  </div>
                                <div className="footer-link" onClick={goToContact}>Contact  </div>
                                <div className="footer-link" onClick={goToSubscribe}>Inscription</div>
                                <div className="footer-link" onClick={goToUnsubscribe}>Désinscription</div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="display-mobile">
                        <Grid container alignItems="center" justify="center">
                            <img src="/img/logo-color.svg" className="footer-logo-mobile"></img>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Grid container direction={desktop?"row":"column"} alignItems="center" justify="center" spacing={4}>
                            <Grid item>
                                <Grid container direction="row" alignItems="center" justify="center">
                                    <img src="/img/logo-payment.png" className="footer-logo-payment"></img>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" alignItems="center" justify="center" className="footer-icons" spacing={2}>
                                    <Grid item xs={4}>
                                        <Grid container direction="column" alignItems="center" justify="center">
                                            <Grid item >
                                                <Grid container alignItems="center" justify="center" className="footer-icon-container" >
                                                    <Grid item>
                                                        <img src="/img/icon2.svg"></img>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <div className="footer-icon-description">Paiement sécurisé</div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Grid container direction="column" alignItems="center" justify="center">
                                            <Grid item >
                                                <Grid container alignItems="center" justify="center" className="footer-icon-container" >
                                                    <Grid item>
                                                        <img src="/img/icon1.svg"></img>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <div className="footer-icon-description">Document officiel</div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Grid container direction="column" alignItems="center" justify="center">
                                            <Grid item>
                                                <Grid container alignItems="center" justify="center" className="footer-icon-container" >
                                                    <Grid item>
                                                        <img src="/img/icon3.svg"></img>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <div className="footer-icon-description">Protection des données</div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Container>
                <Grid container direction="row" alignItems="center" justify={desktop ? "flex-start" : "center"}>
                    <Grid item>
                        <div className="copyrights">© 2021 Tous droits réservés: portail-des-societes.com</div>
                    </Grid>
                </Grid>
            </Container>
        </div >
    )
}

export default Footer