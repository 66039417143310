import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    FormControl, RadioGroup, CircularProgress, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';


const Pros = () => {
    const history = useHistory();
    const desktop = useMediaQuery('(min-width:769px)');

    const goToFunnel = () => {
        history.push("/accueil");
    }

    const goToRegister = () => {
        history.push("/inscription");
    }

    return (
        <section className="lp-section lp-section-service">
            <Grid container direction="column" alignItems="center" justify="center">
                <Grid item>
                    <div className="lp-section-title lp-section-title-pros">
                        Un outil minimaliste et intuitif pour un service adapté
                    </div>
                </Grid>
                <Grid item>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={4}>
                            <div className="lp-pros-container">
                                <Grid container alignItems="center" justify="center" className="lp-pros-image-container">
                                    <Grid item>
                                        <img src="/img/lp/pros1.svg" className="lp-pros-image"></img>
                                    </Grid>
                                </Grid>
                                <Grid container direction="column" alignItems="flex-start" justify="space-between" className="lp-pros-description-container">
                                    <Grid item>
                                        <div className="lp-pros-title">Plus besoin de chercher vos documents partout</div>
                                        <div className="lp-pros-description"> Un partenaire vous demande un ou plusieurs documents pour une collaboration ? partagez lui de manière instantanée grâce à votre espace de centralisation, en entrant son mail et sélectionnant les dossiers à partager !</div>

                                    </Grid>
                                    <Grid item>
                                        <Button color="primary" className="lp-pros-button" endIcon={<ArrowForwardIcon className="big-icon" />} onClick={goToRegister}>Créer un compte</Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div className="lp-pros-container">
                                <Grid container alignItems="center" justify="center" className="lp-pros-image-container">
                                    <Grid item>
                                        <img src="/img/lp/pros2.svg" className="lp-pros-image"></img>
                                    </Grid>
                                </Grid>
                                
                                <Grid container direction="column" alignItems="flex-start" justify="space-between" className="lp-pros-description-container">
                                    <Grid item>
                                    <div className="lp-pros-title">Besoin d’un document en urgence et pas d’internet ? </div>
                                    <div className="lp-pros-description"> Pas de soucis, gardez uniquement le numéro de votre assistant, et sur un simple appel il s’occupera d’envoyer immédiatement les documents à la personne de votre choix depuis votre espace personnel !</div>

                                    </Grid>
                                    <Grid item>
                                        <Button color="primary" className="lp-pros-button" endIcon={<ArrowForwardIcon className="big-icon" />} onClick={goToRegister}>Créer un compte</Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div className="lp-pros-container">
                                <Grid container alignItems="center" justify="center" className="lp-pros-image-container">
                                    <Grid item>
                                        <img src="/img/lp/pros3.svg" className="lp-pros-image"></img>
                                    </Grid>
                                </Grid>
                                <Grid container direction="column" alignItems="flex-start" justify="space-between" className="lp-pros-description-container">
                                    <Grid item>
                                    <div className="lp-pros-title">Aucune erreur possible sur l’obtention de vos justificatifs</div>
                                    <div className="lp-pros-description"> Notre outil intélligent et automatisé, identifie votre entreprise grâce à vos informations et vous met à disposition le justificatif (mis à jour de moins de 3 mois) adéquat pour vos démarches en fonction de votre statut et structure juridique..</div>

                                    </Grid>
                                    <Grid item>
                                        <Button color="primary" className="lp-pros-button" endIcon={<ArrowForwardIcon className="big-icon" />} onClick={goToRegister}>Créer un compte</Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>


                    </Grid>
                </Grid>
            </Grid>
        </section>
    )
}
export default Pros;