import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import MainInput from "../components/MainInput";
import MainCheckbox from "../components/MainCheckbox";
import { getSocietes } from "../services/SocieteService";
import { DataContext } from '../contexts/DataContext';
import { AuthContext } from "../contexts/AuthContext.js";
import { createKbis } from "../services/FileService";
import useAcquisition from "../components/Acquisition";
import { updateAcquisitionInformations } from "../services/AccountService";


const Confirmation = () => {

    const history = useHistory();
    const { societe, setSociete, user, setUser } = useContext(DataContext);
    const desktop = useMediaQuery('(min-width:769px)');
    const { auth } = useContext(AuthContext);
    const acquisition = useAcquisition();


    useEffect(() => {
        async function generateKbis() {
            let kbis = {
                name: societe.name,
                SIREN: societe.siren,
                isInsee: !societe.hasKbis
            }
            let resut = await createKbis(kbis, auth);
        }
        if (!societe.kbisGenerateRequest) {
            generateKbis();
            setSociete((prevState) => ({
                ...prevState,
                kbisGenerateRequest: true,
            }));
        }
        updateAcquisitionInformations(auth, acquisition);
        sendTrustPilotAsk();
    }, []);

    const sendTrustPilotAsk = () => {
        if (process.env.REACT_APP_DOMAIN_EXTENSION == "com" && acquisition.origin != null && acquisition.origin != null && acquisition.origin.indexOf("utm_source=btob2021&utm_medium=keyword&utm_campaign=search_marketing&utm_id=no_brand") >-1  ) {
            const trustpilot_invitation = {
                recipientEmail: user.email,
                recipientName: '',
                referenceId: "Order_" + societe.siren,
                source: 'InvitationScript',
            };
            window.tp('createInvitation', trustpilot_invitation);
            console.log("Invitation trust pilot created");
        }
    }

    const goToAccount = () => {
        history.push("/accueil/mon-compte");
    }

    return (
        <div>
            <div className="title-confirmation display-desktop">
                Votre inscription a été validée, vous allez recevoir votre <br></br> {societe.hasKbis ? "KBIS" : "justificatif officiel"} d’ici quelques minutes.
            </div>
            <div className="title-confirmation display-mobile">
                Votre inscription a été validée, vous allez recevoir votre {societe.hasKbis ? "KBIS" : "justificatif officiel"} d’ici quelques minutes.
            </div>
            <div className="white-container white-container-content">
                <div className="confirmation-information-body">
                    Votre {societe.hasKbis ? "KBIS" : "justificatif officiel"} est en cours d’envoi à l’adresse email que vous nous avez communiquée <span style={{ fontWeight: 500 }}>pensez à vérifier dans vos spams ou indesirables</span>,
                    ainsi qu’un récapitulatif des services associés !
                </div>
                <div className="confirmation-information" style={{ marginTop: "30px" }}>Merci de vous être inscrit à notre plateforme.</div>
                <div className="text-center" style={{ margin: "40px 0px 30px 0px" }}>
                    <Button color="primary" variant="contained" onClick={goToAccount} className="cta" >Acceder à mon {societe.hasKbis ? "KBIS" : "justificatif officiel"}</Button>
                </div>
            </div>
        </div>
    )
}
export default Confirmation;