import api from "./api";


export const getSubscriptionInformations = async (auth) => {
    return await api
        .get(`api/account/SubscriptionInfo`, {
            headers: {
                Authorization: "Bearer " + auth.accessToken,
                Accept: "application/json",
            },
        })
        .catch((error) => {
            console.log(error);
            return false;
        });
}

export const modifyPassword = async (data, auth) => {
    return await api
        .post("/api/account/changepassword", data, {
            headers: {
                Authorization: "Bearer " + auth.accessToken,
                Accept: "application/json",
            },
        })
        .catch((error) => {
            console.log(error);
            return false;
        });
}

export const deleteAccount = async (auth) => {
    return await api
        .get("/api/account/deleteAccount", {
            headers: {
                Authorization: "Bearer " + auth.accessToken,
                Accept: "application/json",
            },
        })
        .catch((error) => {
            console.log(error);
            return false;
        });
}

export const askForUnsubscribe = async (email) => {
    return await api
        .get(`/api/account/askUnsubscribe?email=${email}`)
        .catch((error) => {
            console.log(error);
            return false;
        });
}

export const getTotalProcessingInvoices = async (auth) => {
    return await api
        .get(`api/paymenttp/invoices`, {
            headers: {
                Authorization: "Bearer " + auth.accessToken,
                Accept: "application/json",
            },
        })
        .catch((error) => {
            console.log(error);
            return false;
        });
}

export const getInvoices = async (auth) => {
    return await api
        .get(`/api/account/invoices`, {
            headers: {
                Authorization: "Bearer " + auth.accessToken,
                Accept: "application/json",
            },
        })
        .catch((error) => {
            console.log(error);
            return false;
        });
}


export const updateAcquisitionInformations = async (auth, acquisition) => {
    return await api.post("/api/account/acquisition/", acquisition, {
      headers: {
        Authorization: "Bearer " + auth.accessToken,
        Accept: "application/json",
      },
    }).catch((error) => {
      console.log(error);
      return false;
    });
  }
