import React, { useState, useContext, useEffect } from "react";
import { useHistory, Switch } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Container
} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import MainInput from "../components/MainInput";
import MainCheckbox from "../components/MainCheckbox";
import { getSocietes } from "../services/SocieteService";
import { DataContext } from '../contexts/DataContext';
import { AuthContext } from "../contexts/AuthContext.js";
import { createKbis } from "../services/FileService";
import PrivateRoute from '../components/wrappers/PrivateRoute';
import AccountDetail from "../components/AccountDetail";
import AccountNew from "../components/AccountNew";
import AccountHistory from "../components/AccountHistory";
import AccountInvoice from "../components/AccountInvoice";
import AccountPiecesIdentity from "../components/AccountPiecesIdentity";
import AccountBalanceSheets from "../components/AccountBalanceSheets";
import AccountRIBs from "../components/AccountRIBs";
import AccountOtherDocuments from "../components/AccountOtherDocuments";


const Account = () => {

    const history = useHistory();
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState('/accueil/mon-compte');
    const { societe, setSociete, user, setUser } = useContext(DataContext);
    const desktop = useMediaQuery('(min-width:769px)');
    const { auth, authMethods } = useContext(AuthContext);
    const [openMenu, setOpenMenu] = useState(false);

    const toggleMenu = (e) => {
        e.preventDefault();
        setOpenMenu(!openMenu);
    }

    const logout = () => {
        authMethods.logout();
        history.push("/accueil");
    };

    const goTo = (path) => {
        history.push(path);
    }

    const menuItems = [
        { label: "Historique KBIS", path: "/accueil/mon-compte", component: <AccountHistory></AccountHistory> },
        { label: "Nouveau KBIS", path: "/accueil/mon-compte/nouveau", component: <AccountNew></AccountNew> },
        { label: "Mes pièces d'identité", path: "/accueil/mon-compte/pieces-identites", component: <AccountPiecesIdentity></AccountPiecesIdentity> },
        { label: "Mes bilans comptables", path: "/accueil/mon-compte/bilans-comptables", component: <AccountBalanceSheets></AccountBalanceSheets> },
        { label: "Mes RIBs", path: "/accueil/mon-compte/ribs", component: <AccountRIBs></AccountRIBs> },
        { label: "Mes autres documents", path: "/accueil/mon-compte/autres-documents", component: <AccountOtherDocuments></AccountOtherDocuments> },
        { label: "Factures P.D.S.", path: "/accueil/mon-compte/factures", component: <AccountInvoice></AccountInvoice> },
        { label: "Mon Compte P.D.S.", path: "/accueil/mon-compte/details", component: <AccountDetail></AccountDetail> }
    ]

    return (
        <Grid container spacing={desktop ? 3 : 1} >
            <Grid item xs={12} md={3}  >
                <Grid container>
                    {desktop ? <>{menuItems.map((item, index) =>
                        <Button key={index} fullWidth="true" onClick={() => goTo(item.path)} className={`button-menu ${(item.path === location.pathname) ? "button-menu-selected" : ""}`}  >{item.label}</Button>                   
                    )} <Button onClick={()=>logout()} variant="outlined" color="primary" fullWidth="true" className="header-button" style={{marginTop:"40px"}} >Déconnection</Button></> : <div>
                        <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center">
                            <Grid item>
                                <Button
                                    onClick={toggleMenu}
                                    variant="outlined" color="primary" className="header-button"
                                    style={{marginBottom:"10px"}}
                                >
                                    <Grid container direction="row" alignItems="center" justify="center">
                                         <img className="icon-menu" src="/img/menu-icon.svg"></img> 
                                        <Grid item> Menu</Grid>
                                    </Grid>
                                </Button>
                            </Grid>

                        </Grid>
                        <Collapse in={openMenu} >
                            {
                                menuItems.map((item, index) =>
                                    <Button key={index} fullWidth="true" onClick={() => goTo(item.path)} className={`button-menu ${(item.path === location.pathname) ? "button-menu-selected" : ""}`}  >{item.label}</Button>
                                )
                            }
                            <Button onClick={()=>logout()} variant="outlined" color="primary" fullWidth="true" className="header-button" >Déconnection</Button>
                        </Collapse>
                    </div>}
                    
                    
                </Grid>

            </Grid>
            <Grid item xs={12} md={9}>
                <Switch>
                    {menuItems.slice(0).reverse().map((item, index) =>
                        <PrivateRoute path={item.path} key={index}>
                            {item.component}
                        </PrivateRoute>
                    )}
                </Switch>
            </Grid>
        </Grid>
    )
}
export default Account;