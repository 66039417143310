import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    FormControl, RadioGroup, CircularProgress
} from '@material-ui/core';
import MainInput from "./MainInput";
import MainCheckbox from "./MainCheckbox";
import { getSocietes } from "../services/SocieteService";
import { DataContext } from '../contexts/DataContext';
import RegularInput from "./RegularInput";
import { AuthContext } from "../contexts/AuthContext.js";
import api from "../services/api.js";
import Alert from "@material-ui/lab/Alert";
import { downloadKbis, getKbis } from "../services/FileService";
import { useInterval } from "./Hooks";
import SearchCircular from "./SearchCircular";
import fileDownload from 'js-file-download';


const AccountHistory = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [openError, setOpenError] = useState(false);
    const history = useHistory();
    const { auth, authMethods } = useContext(AuthContext);
    const [kbiss, setKbiss] = useState([]);

    const [formData, setFormData] = useState({
        acceptedTerms: false,
        searchInput: undefined
    });


    async function getAndSetKbiss() {
        var kbisResult = await getKbis(auth);
        if (kbisResult.data) {
            var kbissOrderedByDate = kbisResult.data.sort(function (a, b) {
                return new Date(b.date) - new Date(a.date);
            });
            if (kbisResult) setKbiss(kbissOrderedByDate);
        }
    }

    useEffect(() => {
        getAndSetKbiss();
    }, []);

    useInterval(() => {
        if (kbiss.some(k => !k.available)) {
            getAndSetKbiss();
        }
    }, 5000);


    const desktop = useMediaQuery('(min-width:769px)');

    const downloadFile = async (kbis) => {
        var kbisContent = await downloadKbis(kbis.id, auth);
        fileDownload(kbisContent.data, `${kbis.name}.pdf`);
    }

    return (
        <div className="account-container">
            <div className="account-title">Historique KBIS et justificatifs officiels</div>
            <div className="account-body">
                <Grid container direction="column">
                    {kbiss.map((kbis, index) =>
                        <Grid item key={index} style={{ marginBottom: "10px" }}>
                            <Grid container alignItems="center" justify="space-between"  >
                                <Grid item>{!kbis.isInsee && <>KBIS: </>}{kbis.name} en date du {new Date(kbis.createdAt).toLocaleDateString()}</Grid>
                                <Grid item>
                                    {kbis.available ?
                                        <Button onClick={() => downloadFile(kbis)} color="primary" variant="outlined" className="header-button">Télécharger</Button> :
                                        [
                                            kbis.notExist ? <div style={{ color: "red" }}>KBIS indisponible</div> :
                                                <Grid container alignItems="center" spacing={1} >
                                                    <Grid item>
                                                        <div className="loading-text">En cours (environ 3 minutes)</div>
                                                    </Grid>
                                                    <Grid item>
                                                        <SearchCircular size={20}></SearchCircular>
                                                    </Grid>
                                                </Grid>
                                        ]
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>

            </div>
        </div >
    );
};

export default AccountHistory;
