import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import { useMediaQuery } from '@material-ui/core';


export default function NewsletterInput(props) {

    const desktop = useMediaQuery('(min-width:769px)');

    const useStyles = makeStyles((theme) => ({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: desktop?"359px":"80vw",
            maxWidth: "100%",
            borderRadius: '4px',
            border: "2px solid #FFFFFF",
            height: "40px",
            boxSizing: "border-box",
        },
        input: {
            paddingLeft:"13px",
            paddingRight:"13px",
            flex: 1,
            color:"#ffffff !important"
        }
    }));
    const classes = useStyles();

    return (
        <div component="form" className={classes.root}>
            <InputBase
                className={[classes.input, "lp-newletter-input"].join(' ')}
                {...props}
            />
        </div>
    );
}