import React, { useState, useContext, useEffect } from "react";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    FormControl, RadioGroup, CircularProgress, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle
} from '@material-ui/core';
import TrustBox from "../components/TrustBox";


const Reassurance = () => {
    const desktop = useMediaQuery('(min-width:769px)');

    return (
        <section className="lp-section">
            <Grid container direction="column" alignItems="center" justify="center">
                <Grid item>
                    <div className="lp-section-title">
                        + 20 000 entreprises  utilisent nos services
                    </div>
                    <div className="lp-section-title-description">
                        Avec PLUS DE 50 secteurs d’activités différents & 8 PAYS | sociétés - indépendants ou encore artisans
                    </div>
                </Grid>
                {/** TRUSTPILOT TEMP 
                <Grid item>
                    <div className="lp-reassurance-trustpilot">
                        <TrustBox templateId={"53aa8807dec7e10d38f59f32"} height={"150px"}></TrustBox>
                    </div>
                </Grid>*/}

            </Grid>
        </section>
    )
}
export default Reassurance;