import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";

const RedirectionForm = () => {
    const history = useHistory();
    useEffect(() => {
      setTimeout(()=>{
        history.push("/accueil")
      },300)
    });

    return (
        <div>
            
        </div>
    )
}

export default RedirectionForm;