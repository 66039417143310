import React, { useState, useEffect, createContext } from "react";
import api from "../services/api";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const [auth, setAuth] = useState(() => {
    const localData = typeof localStorage !== 'undefined' && localStorage.getItem("auth");

    // On test si local est un objet JSON (ancienne verison c'etait un string)
    let localDataIsJsonString = true;
    try {
      JSON.parse(localData);
    } catch (e) {
      localDataIsJsonString = false
    }
    
    if (localData && localDataIsJsonString && JSON.parse(localData) !== null) {
      return JSON.parse(localData);
    } else {
      return {
        user: null,
        accessToken: null,
        rememberToken: null,
        isSubscribed: null
      };
    }
  });
  useEffect(() => {
    const getUser = async () => {
      const res = await api.get("/api/Account/UserInfo", {
        headers: {
          Authorization: "Bearer " + auth.rememberToken,
          Accept: "application/json",
        },
      }).then().catch((error) => {
        setAuthNull();
      })
      if (res) {
        setAuth({
          user: res.data.user,
          accessToken: auth.rememberToken,
          rememberToken: auth.rememberToken,
          isSubscribed: res.data.isSubscribed
        })
      }
    }
    if (auth.rememberToken != null && auth.rememberToken != "null") {
      getUser();
    } else {
      setAuthNull()
    }
  }, []);

  const setAuthNull = () => {
    setAuth({
      user: null,
      accessToken: null,
      rememberToken: null,
      isSubscribed: null
    })
  }

  useEffect(() => {
    localStorage.setItem("auth", JSON.stringify(auth));
  }, [auth]);

  const logout = () => {
    setAuth({ user: null, accessToken: null, rememberToken: null, isSubscribed: null });
  };
  const login = (user, accessToken, rememberToken, isSubscribed) => {
    setAuth({ user, accessToken, rememberToken, isSubscribed });
  };

  const isConnected = (authValue) => {
    if (typeof authValue !== "undefined" && authValue !== null &&
      typeof authValue.user !== "undefined" && authValue.user !== null &&
      typeof authValue.accessToken !== "undefined" && authValue.accessToken !== null &&
      typeof authValue.rememberToken !== "undefined" && authValue.rememberToken !== null) {
      return true
    } else {
      return false
    }
  };

  const [authMethods,] = useState({
    logout: logout,
    login: login,
    isConnected: isConnected
  });

  return (
    <AuthContext.Provider value={{ auth, authMethods }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
