import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    FormControl, RadioGroup, CircularProgress, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Container
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';


const CTA = () => {
    const history = useHistory();
    const desktop = useMediaQuery('(min-width:769px)');

    const goToFunnel = () => {
        history.push("/accueil");
    }
    const goToRegister = () => {
        history.push("/inscription");
    }

    return (
        <section className=" tool-section" id="lp-cta">
            <Container>
                <Grid container direction="column" alignItems={desktop?"flex-start":"center"} justify="center">
                    <Grid item>
                        <div className="lp-section-cta-title">
                            Envie de tester ? Alors profitez de notre offre !
                        </div>
                    </Grid>
                    <Grid item >
                        <div className="lp-cpa-1">Accès Illimité</div>
                        <div className="lp-cpa-2">
                            1€/48h
                        </div>
                        <div className="lp-cpa-3">Puis 49,90€/Mois</div>
                    </Grid>
                    <Grid item className="lp-cpa-description-container">
                        <Grid container direction={desktop?"row":"column"} alignItems="center" justify={desktop?"flex-start":"center"} className="lp-cpa-description">
                            <DoneIcon className="lp-cpa-description-icon"></DoneIcon>
                            Vos documents toujours à portée de main
                        </Grid>
                        <Grid container  direction={desktop?"row":"column"} alignItems="center" justify={desktop?"flex-start":"center"} className="lp-cpa-description">
                            <DoneIcon className="lp-cpa-description-icon"></DoneIcon>
                            Abonnement sans engagement et dédié aux professionnels 
                        </Grid>
                        <Grid container  direction={desktop?"row":"column"} alignItems="center" justify={desktop?"flex-start":"center"} className="lp-cpa-description">
                            <DoneIcon className="lp-cpa-description-icon"></DoneIcon>
                            Visualisez, téléchargez ou partagez en 1 clic
                        </Grid>
                    </Grid>
                    <Grid item className="lp-cpa-div-button">
                        <Button variant="contained" color="secondary" endIcon={<ArrowForwardIcon className="big-icon" />} className="lp-cpa-button-2" onClick={goToRegister}>
                            Profiter de l’offre d’essai
                        </Button>
                    </Grid>
                </Grid>

            </Container>
        </section>
    )
}
export default CTA;