import React, { useState, useEffect, createContext } from "react";

export const DataContext = createContext();

const DataContextProvider = (props) => {
    const [societe, setSociete] = useState(() => {
        const localData = typeof localStorage !== 'undefined' && localStorage.getItem("societe");
        return localData
            ? JSON.parse(localData)
            : {};
    });
    useEffect(() => {
        localStorage.setItem("societe", JSON.stringify(societe));
    }, [societe]);

    const [user, setUser] = useState(() => {
        const localData = typeof localStorage !== 'undefined' && localStorage.getItem("user");
        return localData
            ? JSON.parse(localData)
            : {};
    });
    useEffect(() => {
        localStorage.setItem("user", JSON.stringify(user));
    }, [user]);

    return (
        <DataContext.Provider value={{ societe, setSociete, user, setUser}}>
            {props.children}
        </DataContext.Provider>
    );
};

export default DataContextProvider;