import React, { useState, useContext, useEffect } from "react";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    FormControl, RadioGroup, CircularProgress, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Container
} from '@material-ui/core';


const Tool = () => {
    const desktop = useMediaQuery('(min-width:769px)');

    return (
        <section className=" tool-section" >
            <Container>
            <Grid container direction="column" alignItems="center" justify="center">
                <Grid item>
                    <div className="lp-section-title">
                        Un outil simple <br className="display-mobile" /> et sécurisé 
                    </div>
                </Grid>
                <Grid item >
                    <Grid container direction="row" alignItems="center" justify="center" spacing={3}>
                        <Grid item xs={12} md={3}>
                            <Grid container direction="column" alignItems="center" justify="center">
                                <Grid item>
                                    <img src="/img/lp/tool1.svg" className="lp-tool-img"></img>
                                </Grid> 
                                <Grid item>
                                    <div className="lp-tool-title">Fiable</div>
                                </Grid> 
                                <Grid item>
                                    <div className="lp-tool-description">Un outil sécurisé et intelligeant, aucune erreur possible dans l’identification de votre société et recherche de justificatifs.</div>
                                </Grid> 
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Grid container direction="column" alignItems="center" justify="center">
                                <Grid item>
                                    <img src="/img/lp/tool2.svg" className="lp-tool-img"></img>
                                </Grid> 
                                <Grid item>
                                    <div className="lp-tool-title">Simple</div>
                                </Grid> 
                                <Grid item>
                                    <div className="lp-tool-description">Téléchargez, visualisez ou partagez vos documents en 2 clics, depuis une interface et un outil minimaliste et adapté à vos besoins.</div>
                                </Grid> 
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Grid container direction="column" alignItems="center" justify="center">
                                <Grid item>
                                    <img src="/img/lp/tool3.svg" className="lp-tool-img"></img>
                                </Grid> 
                                <Grid item>
                                    <div className="lp-tool-title">Tout-en-un</div>
                                </Grid> 
                                <Grid item>
                                    <div className="lp-tool-description">Tous vos documents professionnels, essentiels à vos dossiers et collaboration dans un même espace et à portée de main.</div>
                                </Grid> 
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Grid container direction="column" alignItems="center" justify="center">
                                <Grid item>
                                    <img src="/img/lp/tool4.svg" className="lp-tool-img"></img>
                                </Grid> 
                                <Grid item>
                                    <div className="lp-tool-title">À l’écoute</div>
                                </Grid> 
                                <Grid item>
                                    <div className="lp-tool-description">Votre conseiller dédié, est à l’écoute pour vous apporter des solutions et vous assister en un temps minimum 6jours/7.</div>
                                </Grid> 
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
            </Container>
        </section>
    )
}
export default Tool;