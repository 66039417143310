import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    FormControl, RadioGroup, CircularProgress, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle
} from '@material-ui/core';
import MainInput from "./MainInput";
import MainCheckbox from "./MainCheckbox";
import { getSocietes } from "../services/SocieteService";
import { DataContext } from '../contexts/DataContext';
import RegularInput from "./RegularInput";
import { AuthContext } from "../contexts/AuthContext.js";
import api from "../services/api.js";
import Alert from "@material-ui/lab/Alert";
import { modifyPassword, deleteAccount, getSubscriptionInformations } from "../services/AccountService";
import { cancelSubscription } from "./totalprocessing/PaymentTotalProcessingService";
import NewCardTotalProcessing from "./totalprocessing/NewCardTotalProcessing";
import queryString from 'query-string';

const AccountDetail = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [openError, setOpenError] = useState(false);
    const history = useHistory();
    const { auth, authMethods } = useContext(AuthContext);
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState();
    const [changeCard, setChangeCard] = useState(false);
    const { search } = useLocation();
    const [subscriptionInfo, setSubscriptionInfo] = useState({})

    const [openCU, setOpenCU] = React.useState(false);
    const handleClickOpenCU = () => {
        setOpenCU(true);
    };
    const handleCloseCU = () => {
        setOpenCU(false);
    };

    const [openV, setOpenV] = React.useState(false);
    const handleClickOpenV = () => {
        setOpenV(true);
    };
    const handleCloseV = () => {
        setOpenV(false);
    };

    const [openConfirmed, setOpenConfirmed] = React.useState(false);
    const handleClickOpenConfirmed = () => {
        setOpenConfirmed(true);
    };
    const handleCloseConfirmed = () => {
        setOpenConfirmed(false);
    };

    useEffect(() => {
        getSubscriptionInformations(auth).then(res => {
            setSubscriptionInfo(res.data)
        })

        const values = queryString.parse(search)
        // Back from Total Processing payment
        if (values.id != null) {
            setChangeCard(true)
        }
    }, []);

    const [formData, setFormData] = useState({
        oldPassword: undefined,
        newPassword: undefined
    });

    const setField = (field) => (event) => {
        event.persist();
        setFormData((prevState) => ({
            ...prevState,
            [field]: event.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        setOpen(false);
        e.preventDefault();
        var response = modifyPassword({
            oldPassword: formData.oldPassword,
            newPassword: formData.newPassword,
            confirmPassword: formData.newPassword
        }, auth);
        if (response) {
            setMessage("Le mot de passe a bien été modifié.");
            setSeverity("success");
            return setOpen(true);
        } else {
            setMessage("Erreur lors de l'enregistrement du mot de passe.");
            setSeverity("error");
            return setOpen(true);
        }
    }

    const unsubscribe = async (e) => {
        e.preventDefault();
        // Période de 4 heures avant de permettre la desinscription.
        const diffTime = Math.abs(Date.now() - new Date(auth.user.createdAt));
        const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
        if (diffHours > 4) {
            handleClickOpenV();
        } else {
            handleClickOpenCU();
        }
    }

    const unsubscribeValidation = async (e) => {
        e.preventDefault();
        var result = false;

        /*try {
            result = await deleteAccount(auth);
            console.log(result)
        } catch (error) {
            console.log("Not a stripe user")
        }*/

        try {
            var cancelRes = await cancelSubscription(auth);
            console.log(cancelRes)
            result = true;
        } catch (error) {
            console.log("Not a stripe user")
        }

        if (result) {
            handleCloseV();
            handleClickOpenConfirmed();
            setTimeout(() => {
                authMethods.logout();
                history.push("/accueil");
            }, 10000);
        }
    }

    const accountDeleted = (e) => {
        authMethods.logout();
        history.push("/accueil");
    }

    const changeCardValue = (value) => {
        setChangeCard(value);
        getSubscriptionInformations(auth).then(res => {
            setSubscriptionInfo(res.data)
        })
    }


    const desktop = useMediaQuery('(min-width:769px)');

    return (
        <div className="account-container">
            <div className="account-title">Mon Compte</div>
            <div className="account-body">
                {
                    !auth.isSubscribed &&
                    <div className="account-block">
                        <div className="account-block-data"> <span className="account-block-value-important">L'abonnement est résilié</span></div>
                    </div>
                }
                <div className="account-block">
                    <div className="account-block-title">Informations personnelles</div>
                    <div className="account-block-data">Email : <span className="account-block-value">{auth.user.email}</span></div>
                    <div className="account-block-data">Numéro client : <span className="account-block-value">{auth.user.uid}</span></div>
                </div>
                <div className="account-block">
                    <div className="account-block-title">Changement de mot de passe</div>
                    <form onSubmit={handleSubmit}>
                        <Collapse in={open}>
                            <Alert
                                style={{ marginBottom: "15px" }}
                                severity={severity}
                                action={
                                    <i
                                        className="fas fa-times"
                                        style={{ color: "inherit", cursor: "pointer" }}
                                        onClick={() => setOpen(false)}
                                    ></i>
                                }
                            >
                                {message}
                            </Alert>
                        </Collapse>
                        <Grid container spacing={desktop ? 3 : 1}>
                            <Grid item xs={12} md={6}>
                                <RegularInput
                                    placeholder="Mot de passe actuel "
                                    value={formData.oldPassword}
                                    onChange={setField("oldPassword")}
                                    required
                                    type="password"></RegularInput>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RegularInput
                                    placeholder="Nouveau mot de passe"
                                    value={formData.newPassword}
                                    onChange={setField("newPassword")}
                                    required
                                    type="password"></RegularInput></Grid>
                        </Grid>
                        <Grid container direction="row" justify="flex-end">
                            <Button variant="contained" color="primary" className="cta" type="submit">Modifer mot de passe</Button>
                        </Grid>
                    </form>
                </div>
                <div className="account-block">
                    <div className="account-block-title">Informations de paiement</div>
                    {
                        changeCard ?
                            <NewCardTotalProcessing setChangeCard={changeCardValue} /> :
                            <CardInformations last4={subscriptionInfo?.cardLast4Digits} expiry={`${subscriptionInfo?.cardExpiryMonth}/${subscriptionInfo?.cardExpiryYear}`} cardHolder={subscriptionInfo?.cardHolderName} setChangeCard={setChangeCard} />
                    }


                </div>
                {
                    auth.isSubscribed &&
                    <div className="account-block">
                        <div className="account-block-title">Désinscription </div>
                        <Typography variant="body1">
                            Vous pouvez résilier votre abonnement à tout moment en cliquant sur le bouton ci-dessous: "Se désinscrire". <br></br>
                            Ce que vous devez savoir:<br></br>
                            Vous ne pourrez plus génerer de KBIS. <br></br>
                            Vous perdez vos droits aux services de la plateforme. <br></br>
                            Plus aucun prélèvement ne sera effectué.
                        </Typography>
                        <Button variant="contained" color="primary" className="cta" type="button" onClick={unsubscribe}>Se désinscrire</Button>
                    </div>
                }
            </div>
            <Dialog
                open={openCU}
                onClose={handleCloseCU}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        La résiliation peut-être effectuée à minima 4 heures après l'inscription.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCU} color="primary" className="header-button" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openV}
                onClose={handleCloseV}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Validation de désinscription</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Attention si vous vous désinscrivez : <br></br>
                        Vous ne pourrez plus génerer de KBIS. <br></br>
                        Vous perdez vos droits aux services de la plateforme. <br></br>
                        Plus aucun prélèvement ne sera effectué.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseV} color="primary" className="header-button" autoFocus>
                        Annuler
                    </Button>
                    <Button onClick={unsubscribeValidation} color="primary" className="header-button">
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openConfirmed}
                onClose={handleCloseConfirmed}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Désinscription réalisée avec succès</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Votre inscription a bien été résiliée.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={accountDeleted} color="primary" className="header-button">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

const CardInformations = (props) => {
    return (
        <Grid container direction={{ xs: "column", md: "row" }} alignItems="center" style={{ marginTop: "40px" }} spacing="20px">
            {
                props.cardHolder != null &&
                <Grid xs="12" md="5" item container direction="column" alignItems="center">
                    <Grid item container direction="row">
                        {/* <Grid item>
                        {props.brand == "mastercard" && <Grid item><div className="card-icon-container-3"><img src="/img/master-card-clean.svg" className="icon-card-2" /></div></Grid>}
                        {props.brand == "visa" && <Grid item><div className="card-icon-container-3"><img src="/img/visa-clean.png" className="icon-card-2" /></div></Grid>}
                        {props.brand == "amex" && <Grid item><div className="card-icon-container-3"><img src="/img/american-express2.jpg" className="icon-card-2" /></div></Grid>}
                    </Grid> */}
                        <Grid item style={{ marginLeft: "20px" }}>
                            <Typography variant="body2" className="text-center" style={{ fontSize: "20px", fontWeight: "600", color: "#000000" }}>{props.cardHolder}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container direction="row" style={{ marginTop: "15px" }}>
                        <Grid item style={{ marginLeft: "20px" }}>
                            <Typography variant="body2" className="text-center" style={{ fontSize: "16px", color: "#717171" }}><span>∗∗∗∗ ∗∗∗∗ ∗∗∗∗</span> {props.last4}</Typography>
                        </Grid>
                        <Grid item style={{ marginLeft: "20px" }}>
                            <Typography variant="body2" className="text-center" style={{ fontSize: "16px", color: "#717171" }}>{props.expiry}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            }
            <Grid item xs="12" md="3" className="changeCardButton">
                <Button variant="contained" color="primary" className="cta-card" type="button" onClick={() => props.setChangeCard(true)}>
                    Mettre à jour
                </Button>
            </Grid>
        </Grid>
    )
}

export default AccountDetail;
