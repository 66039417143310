

import Dialog from '@material-ui/core/Dialog';

const VideoDialog = (props) => {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog 
                fullWidth={true}
                maxWidth="md" onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <div className="video-responsive">
                <iframe
                    width="853"
                    height="480"
                    src={`https://www.youtube.com/embed/ucMjAhzzRXA?&autoplay=1`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Portail des société pub TV"
                />
            </div>
        </Dialog>
    );
}

export default VideoDialog;