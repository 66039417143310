import Alert from "@material-ui/lab/Alert";
import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import RegularInput from "../components/RegularInput";
import { AuthContext } from "../contexts/AuthContext";
import api from "../services/api";

const { Grid, Button, Container, useMediaQuery,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Collapse } = require("@material-ui/core");

function Header(props) {
    const history = useHistory();
    const location = useLocation();
    const desktop = useMediaQuery('(min-width:769px)');
    const { auth, authMethods } = useContext(AuthContext);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogForgotPassword, setOpenDialogForgotPassword] = React.useState(false);
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        email: null,
        password: null
    });

    const setField = (field) => (event) => {
        event.persist();
        setFormData((prevState) => ({
            ...prevState,
            [field]: event.target.value,
        }));
    };

    const setFieldValue = (field) => (value) => {
        setFormData((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const setFieldChecked = (field) => (event) => {
        setFormData((prevState) => ({
            ...prevState,
            [field]: event.target.checked,
        }));
    };
    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleClickOpenDialogForgotPassword = () => {
        setOpenDialogForgotPassword(true);
    };
    const handleCloseDialogForgotPassword = () => {
        setOpenDialogForgotPassword(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setOpen(false);
        api.post("/Token",
            "grant_type=password&username=" + formData.email + "&password=" + formData.password
            , {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then((tokenObject) => {
                api.get("/api/Account/UserInfo", {
                    headers: {
                        Authorization: "Bearer " + tokenObject.data.access_token,
                        Accept: "application/json",
                    },
                }).then((userInfo) => {
                    var data = userInfo.data;
                    authMethods.login(data.user, tokenObject.data.access_token, tokenObject.data.access_token, data.isSubscribed);
                    handleCloseDialog();
                    setFormData({
                        email: null,
                        password: null
                    })
                    goToAccount();
                }).catch((error) => {
                    setOpen(true);
                });
            }).catch((error) => {
                setOpen(true);
            });
    };

    const handleSubmitForgotPassword = (e) => {
        e.preventDefault();
        api.get(`/api/Account/ResetPassword?email=${formData.email}`).then((userInfo) => {
            handleCloseDialogForgotPassword();
        }).catch((error) => {
            console.log(error);
            handleCloseDialogForgotPassword();
        });
    }

    const goToforgotPassword = (e) => {
        handleCloseDialog();
        handleClickOpenDialogForgotPassword();
    }

    const goToHomePage = () => {
        switch (location.pathname) {
            case '/accueil/email':
                break;
            case '/accueil/paiement':
                break;
            case '/accueil/avis':
                break;
            case '/accueil':
                break;
            default:
                history.push("/services");
        }
    }

    const goToAccount = () => {
        history.push("/accueil/mon-compte");
    }

    return (
        <Container style={{ paddingTop: desktop ? "26px" : "39px" }} >
            <Grid container direction="row" alignItems="center" justify="space-between"  >
                <Grid item onClick={goToHomePage}> <img src="/img/logo-bw.svg" className="logo" ></img> </Grid>
                <Grid item>
                    <Grid container alignItems="center" justify="flex-end" spacing={3}>
                        <Grid item className="display-desktop">
                            <Grid container direction="column">
                                <Grid item><div className="info-logos">Service indépendant, données générées &  accessibles depuis:</div></Grid>
                                <Grid container direction="row" alignItems="center" >
                                    <Grid item><img src="/img/partner1.svg" className="partner-image"></img></Grid>
                                    <Grid item><img src="/img/partner2.svg" className="partner-image" style={{ marginLeft: "17px", marginRight: "19px" }}></img></Grid>
                                    <Grid item><img src="/img/partner3.svg" className="partner-image" style={{ marginRight: "13px" }}></img></Grid>
                                    <Grid item><img src="/img/partner4.svg" className="partner-image"></img></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            {auth.accessToken != null ? <Button variant="outlined" color="primary" className="header-button" onClick={goToAccount} style={{ minWidth: "122px" }}>Mon Compte</Button> :
                                <Button variant="outlined" color="primary" className="header-button" onClick={handleClickOpenDialog} style={{ minWidth: "122px" }}>Se Connecter</Button>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title" maxWidth="md">
                <DialogTitle id="form-dialog-title">Se Connecter</DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <Collapse in={open}>
                            <Alert
                                style={{ marginBottom: "15px" }}
                                severity="error"
                                action={
                                    <i
                                        className="fas fa-times"
                                        style={{ color: "inherit", cursor: "pointer" }}
                                        onClick={() => setOpen(false)}
                                    ></i>
                                }
                            >
                                L'email, le mot de passe ou les deux sont incorrects.
                            </Alert>
                        </Collapse>
                        <div >
                            <RegularInput
                                autoFocus
                                placeholder="E-mail"
                                value={formData.email}
                                onChange={setField("email")}
                                required
                                type="email"
                            ></RegularInput>
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <RegularInput
                                placeholder="Mot de passe"
                                value={formData.password}
                                onChange={setField("password")}
                                required
                                type="password"
                            ></RegularInput>
                        </div>
                        <div >
                            <div className="forgot-password" onClick={goToforgotPassword}>
                                Mot de passe oublié ?
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary" className="header-button">
                            Annuler
                        </Button>
                        <Button type="submit" color="primary" className="header-button">
                            Connection
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog open={openDialogForgotPassword} onClose={handleCloseDialogForgotPassword} aria-labelledby="form-dialog-title" maxWidth="md">
                <DialogTitle id="form-dialog-title">Mot de passe oublié</DialogTitle>
                <form onSubmit={handleSubmitForgotPassword}>
                    <DialogContent>
                        <div className="forgot-password-description">
                            Entrez votre email pour recevoir un email de réinitialisation de votre mot de passe
                        </div>
                        <div >
                            <RegularInput
                                autoFocus
                                placeholder="E-mail"
                                value={formData.email}
                                onChange={setField("email")}
                                required
                                type="email"
                            ></RegularInput>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialogForgotPassword} color="primary" className="header-button">
                            Annuler
                        </Button>
                        <Button type="submit" color="primary" className="header-button">
                            Réinitialiser mon mot de passe
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Container>
    )
}

export default Header