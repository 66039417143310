import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Container, Box
} from '@material-ui/core';
import Footer from '../layout/footer';
import { DataContext } from '../contexts/DataContext';
import { AuthContext } from "../contexts/AuthContext.js";
import HeaderLP from "../lp/headerLP";
import '../LP.css';
import GoTop from "../lp/goTop";
import Scroll from "react-scroll";
import RegularInputRegister from "../components/RegularInputRegister";
import PaymentFormRegisterTotalProcessing from "../components/totalprocessing/PaymentFormRegisterTotalProcessing";
import queryString from 'query-string';
import { Alert } from "@material-ui/lab";

const ScrollLink = Scroll.Link;

var dataLayer;

const RegisterPage = () => {
    const [blurInfos, setBlurInfos] = useState(false);
    const history = useHistory();
    const { search } = useLocation();
    const [openError, setOpenError] = useState(false);

    const { societe, setSociete, user, setUser } = useContext(DataContext);
    const desktop = useMediaQuery('(min-width:769px)');
    const { auth } = useContext(AuthContext);
    const [mailValidated, setMailValidated] = useState(false);

    const [formData, setFormData] = useState({
        email: null
    });

    const setField = (field) => (event) => {
        event.persist();
        setFormData((prevState) => ({
            ...prevState,
            [field]: event.target.value,
        }));
        setUser((prevState) => ({
            ...prevState,
            email: event.target.value,
        }));
    };

    useEffect(() => {
        const values = queryString.parse(search)
        // Back from Total Processing payment
        if (values.id != null) {
            setMailValidated(true)
        }
    }, []);

    const goToFunnel = () => {
        history.push("/accueil");
    }

    const validateEmail = () => {
        setOpenError(false);
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)) {
            setMailValidated(true);
        } else {
            setOpenError(true);
        }
    }

    return (
        <Box sx={{
            "-webkit-filter": blurInfos ? "blur(2.5px)" : "blur(0px)",
            "-moz-filter": blurInfos ? "blur(2.5px)" : "blur(0px)",
            "-o-filter": blurInfos ? "blur(2.5px)" : "blur(0px)",
            "-ms-filter": blurInfos ? "blur(2.5px)" : "blur(0px)",
            "filter": blurInfos ? "blur(2.5px)" : "blur(0px)",
        }}>
            <div className="page-container">
                <div className="main-with-header lp-main-with-header lp-register-main-content">

                    <img src="/img/lp/bg-register-1.svg" className="lp-register-bg-1"></img>
                    <img src="/img/lp/bg-register-2.svg" className="lp-register-bg-2"></img>

                    {/* 
            <img src={desktop ? "/img/lp/bg-1.svg" : "/img/lp/bg-1-mobile.svg"} className="lp-bg-1"></img>
            <img src="/img/lp/bg-2.svg" className="lp-bg-2"></img>
            */}

                    <HeaderLP></HeaderLP>
                    <div className="main">
                        <Container>
                            <div className="main-content lp-main-content lp-register-main-pannel-container">
                                <Grid container className="lp-register-main-pannel" direction="row"  >
                                    <Grid item xs={12} md={6} className="lp-register-main-pannel-left" style={{ position: "relative", minHeight: desktop ? "250px" : "" }}>
                                        <div className="lp-register-pannel-title" style={{ marginBottom: desktop ? "40px" : "45px" }}>
                                            Remplissez le formulaire {desktop && <br />} et créez votre compte en 2min
                                        </div>

                                        <div className="label-reagular-input-register ">Adresse E-mail</div>
                                        <RegularInputRegister
                                            autoFocus
                                            placeholder="E-mail..."
                                            value={formData.email}
                                            onChange={setField("email")}
                                            required
                                            disabled={mailValidated}
                                            type="email"
                                        ></RegularInputRegister>
                                        <Collapse in={openError} style={{ margin: "20px 0px 40px" }}>
                                            <Alert
                                                style={{ marginBottom: "15px" }}
                                                severity="error"
                                                action={
                                                    <i
                                                        className="fas fa-times"
                                                        style={{ color: "inherit", cursor: "pointer" }}
                                                        onClick={() => setOpenError(false)}
                                                    ></i>
                                                }
                                            >
                                                Votre adresse email n'est pas valide
                                            </Alert>
                                        </Collapse>
                                        <Grid container direction="row" justifyContent="center">
                                            {
                                                !mailValidated &&
                                                < Button onClick={validateEmail} variant="contained" color="primary" className="cta-payment-no-margin" style={{ width: "116px", position: desktop ? "absolute" : "relative", bottom: desktop ? "0px" : "unset", marginTop: desktop ? "unset" : "20px", right: desktop ? "45px" : "auto" }}>
                                                    <Grid container alignItems="center" justifyContent="space-between" >
                                                        <Grid item>Valider</Grid>
                                                        <img src="/img/arrow-right2.svg" ></img>
                                                    </Grid>
                                                </Button>
                                            }
                                        </Grid>
                                    </Grid>
                                    {!desktop && <div className="lp-register-seprator"></div>}
                                    <Grid item xs={12} md={6} className="lp-register-main-pannel-right" style={{marginBottom:"60px"}}>
                                        <div className="lp-register-pannel-title lp-register-pannel-title-right" style={{ marginBottom: "8px" }}>
                                            Profitez de notre Offre d’essai {desktop && <br />} à <span style={{ color: "#0DE3B0" }}>1€/48h</span>
                                            <span style={{ fontSize: desktop ? "18px" : "13px" }}> puis 49,90€/mois</span>
                                        </div>
                                        <Grid container direction="column" className="lp-register-fee" style={{ marginBottom: "17px" }}>
                                            <Grid item style={{ marginTop: "12px", marginBottom: "8px" }}>
                                                <Grid container direction="row" justify="space-between" alignItems="center">
                                                    <Grid item className="lp-register-fee-text"> Tarif </Grid>
                                                    <Grid item className="lp-register-fee-text"> 1€ </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <div className="lp-register-fee-separator"></div>
                                            </Grid>
                                            <Grid item style={{ marginTop: "11px" }}>
                                                <Grid container direction="row" justify="space-between" alignItems="center">
                                                    <Grid item className="lp-register-fee-subtext"> Accès complet - Essai </Grid>
                                                    <Grid item className="lp-register-fee-subtext"> 0,50€ </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item style={{ marginTop: "5px", marginBottom: "14px" }}>
                                                <Grid container direction="row" justify="space-between" alignItems="center">
                                                    <Grid item className="lp-register-fee-subtext"> Vérification administrative </Grid>
                                                    <Grid item className="lp-register-fee-subtext"> 0,50€ </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {
                                            mailValidated &&
                                            <>
                                                <PaymentFormRegisterTotalProcessing email={formData.email} blurInfos={blurInfos} setBlurInfos={setBlurInfos} />
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </Container>
                    </div>
                </div >
                <Footer></Footer>
                <GoTop scrollStepInPx="100" delayInMs="10.50" />
            </div >
        </Box>
    )
}
export default RegisterPage;