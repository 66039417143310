import api from "./api";


export const userExists = async (email) => {
    return await api.get(`/api/Avis/UserExists?email=${email}`, {
        headers: {
            Accept: "application/json",
        },
    }).catch((error) => {
        console.log(error);
        return false;
    });
}

export const createAvis = async (data) => {
    return await api.post(`/api/Avis/ProceedComplete`, data, {
        headers: {
            Accept: "application/json",
        },
    }).catch((error) => {
        console.log(error);
        return false;
    });
}