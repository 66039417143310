import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery,
    FormControl, RadioGroup, CircularProgress, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';


const Register = () => {

    
    const history = useHistory();

    const goToContact = () => {
        history.push("/accueil/contact");
    }
    const goToFunnel = () => {
        history.push("/accueil");
    }
    const goToRegister = () => {
        history.push("/inscription");
    }

    const desktop = useMediaQuery('(min-width:769px)');

    return (
        <section className="lp-section lp-section-register" id="lp-register">
            <Grid container direction="column" alignItems="center" justify="center">
                <Grid item>
                    <div className="lp-section-title">
                        Prêt à simplifier votre gestion ? Alors inscrivez vous en 2 clics
                    </div>
                </Grid>
                <Grid container direction={desktop?"row":"column"} alignItems={desktop?"flex-start":"center"} justify={desktop?"space-around":"center"}>
                    <Grid item>
                        <Grid container direction="column" alignItems="center" justify="center">
                            <Grid item>
                                <img src="/img/lp/register1.svg"></img>
                            </Grid>
                            <Grid item><div className="lp-register-title">En téléchargeant votre <br /> 1er document</div></Grid>
                            <Grid item><div className="lp-register-description">Téléchargez votre justificatifs, <br /> votre compte est <br /> automatiquement créé et vous <br /> donne accès à tous nos services</div></Grid>
                            <Grid item><Button color="primary" endIcon={<ArrowForwardIcon className="big-icon"/>} className="lp-register-button" onClick={goToFunnel} >Télécharger un document</Button> </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <div className="lp-register-or">ou</div>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" alignItems="center" justify="center">
                            <Grid item>
                                <img src="/img/lp/register2.svg"></img>
                            </Grid>
                            <Grid item><div className="lp-register-title">En créant directement <br /> votre compte </div></Grid>
                            <Grid item><div className="lp-register-description">Pas besoin de justificatif pour l’instant, <br /> mais envie de tester ou bénéficier de <br /> nos services ? <br /> Alors vous pouvez créer votre espace</div></Grid>
                            <Grid item><Button color="primary" endIcon={<ArrowForwardIcon className="big-icon"/>} className="lp-register-button"  onClick={goToRegister}>Créer un compte</Button> </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <div className="lp-register-or">ou</div>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" alignItems="center" justify="center">
                            <Grid item>
                                <img src="/img/lp/register3.svg"></img>
                            </Grid>
                            <Grid item><div className="lp-register-title">En faisant une demande <br /> au support client</div></Grid>
                            <Grid item><div className="lp-register-description">Vous préférez être accompagné dans la <br /> prise en main de l’outil ou confier la <br /> création de votre compte à nos experts ? <br /> Ils vous attendent ! </div></Grid>
                            <Grid item><Button color="primary" endIcon={<ArrowForwardIcon className="big-icon"/>} className="lp-register-button"  onClick={goToContact} >Contacter nos experts</Button> </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <div className="lp-section-title lp-section-title-question">
                        Vous avez des questions ?
                    </div>
                    <div className="lp-section-title-description2">
                        Nos experts sont là pour vous aider, disponibles 6/7j et 20/24h, alors n’hésitez pas !
                    </div>
                </Grid>

                <Grid item>
                    <Button variant="contained" color="primary" className="lp-register-button-contact" endIcon={<ArrowForwardIcon className="big-icon" />}  onClick={goToContact}> Nous contacter</Button>
                </Grid>
            </Grid>
        </section>
    )
}
export default Register;