import React, { useState, useContext, useEffect } from "react";
import {
    Typography, Grid, FormControlLabel, Checkbox, Button, Collapse, useMediaQuery
} from '@material-ui/core';
import MainInput from "../components/MainInput";
import MainCheckbox from "../components/MainCheckbox";
import { getSocietes, hasKbis } from "../services/SocieteService";
import { DataContext } from '../contexts/DataContext';
import { useHistory } from "react-router-dom";
import SearchCircular from "../components/SearchCircular";

const Search = () => {

    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const { societe, setSociete } = useContext(DataContext);

    const [formData, setFormData] = useState({
        email: null
    });

    useEffect(() => {
        async function sirenHasKbis() {
            var s = await hasKbis(societe.siren);
            if (s) setSociete((prevState) => ({
                ...prevState,
                hasKbis: s.data != null ? s.data : true
            }));
        }
        var waitSomeTime = new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 2500);
        });
        Promise.all([sirenHasKbis(),waitSomeTime]).then(()=>history.push("/accueil/email"));
    },[])


    const setField = (field) => (event) => {
        event.persist();
        setFormData((prevState) => ({
            ...prevState,
            [field]: event.target.value,
        }));
    };

    const setFieldValue = (field) => (value) => {
        setFormData((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const setFieldChecked = (field) => (event) => {
        setFormData((prevState) => ({
            ...prevState,
            [field]: event.target.checked,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Todo
    };

    const desktop = useMediaQuery('(min-width:769px)');

    return (
        <div>
            <Grid container direction={desktop ? 'row' : 'column'}>
                <Grid item xs={12} md={9}>
                    <Typography variant="h1" className="nowrap">Votre <span className="color2">KBIS</span> officiel <span className="display-desktop">de moins de 3 mois</span> en 1 clic</Typography>
                    <Typography variant="h2">Obtenez votre document certifié et mis à jour.<br ></br> Sans délai en téléchargement. </Typography>
                    <div className="white-container">
                        <div className="progress">
                            <div className="progress-state progress-state-2"></div>
                        </div>
                        <div className="white-container-content white-container-search green-container-content">
                            <Grid container direction="column" alignItems={desktop?"flex-start":"center"}  >
                                <Grid item><div className="box-step">Étape 2/3</div></Grid>
                                <Grid item><div className="box-title">Recherche du Kbis demandé</div></Grid>
                                <Grid item><div className="search-subtitle">Veuillez ne pas quitter la page</div></Grid>
                            </Grid>
                            <Grid container direction="column" alignItems="center" justify="center">
                                <Grid item> <SearchCircular></SearchCircular></Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={3} className="right-side-image-container">
                    <img className="right-side-image right-side-image-search" src="/img/search-doc.svg"></img>
                </Grid>
            </Grid>
            <Grid container direction="column" alignItems="center" justify="center" className="display-mobile" style={{ marginTop: "22px" }}  >
                <Grid item><div className="info-logos">Service indépendant, données générées & accessibles depuis:</div></Grid>
                <Grid container direction="row" alignItems="center" justify="center"  >
                    <Grid item><img src="/img/partner1.svg"></img></Grid>
                    <Grid item><img src="/img/partner2.svg" style={{ marginLeft: "17px", marginRight: "19px" }}></img></Grid>
                    <Grid item><img src="/img/partner3.svg" style={{ marginRight: "13px" }}></img></Grid>
                    <Grid item><img src="/img/partner4.svg"></img></Grid>
                </Grid>
            </Grid>
            <Grid container spacing={desktop ? 6 : 0}>
                <Grid item xs={12} md={6}>
                    <div className="info-title">Extrait k, Extrait Kbis ou Extrait D1</div>
                    <div className="info-body">
                        L’Extrait K ou extrait Kbis ou extraits D1, sont ce que l’on considère comme étant la carte d’identité d’une entreprise, quelque en soit la taille : Artisans, autoentreprise, microentreprise ou grands groupes, à partir du moment ou celles-ci sont enregistrées au Registre du Commerce & des Sociétés (RCS). Le document compile toutes les informations de la société et fait état, des mentions portées par le greffier chargé de la tenue de ce registre. Il s'agit du seul document officiel prouvant l'identité, l’activité et l'adresse de la structure morale ou physique.<br></br>
                        Dans la majeure partie des cas, dans le cadre de démarches, ou pour être opposable, le document doit être daté de moins de 3 mois.
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className="info-title">Prestations portail-des-societes.com</div>
                    <div className="info-body">
                        portail-des-societes.com est un portail vous permettant de télécharger vos Kbis ou autres documents justificatifs avec réception immédiate,  en toute simplicité ! <br />
                        Il est aussi possible de stocker tous les documents indispensables via un espace sécurisé (en savoir plus sur nos services). Notre solution est indépendante des greffes et des tribunaux de commerce, permettant ainsi de recevoir son Kbis rapidement, à moindres frais via notre offre d’essai et sans délai. De plus, notre plateforme donne automatiqueent à partir du premier téléchargement de document à 1€, l’accès à un espace personnel permettant de gérer tous vos documents et informations importantes pour votre société et un historique de vos Kbis certifiés, via un abonnement mensuel sans engagement à 49,90€ par mois (tarifs).
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
export default Search;